import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link/link';

import styles from './venue-label.module.scss';

const VenueLabel = ({venue, path}) => {
  return (
    <div className={styles.label}>
      <Link to={path}  aria-label={`Navigate to ${venue}`}>{venue}</Link>
    </div>
  );
};

VenueLabel.propTypes = {
  venue: PropTypes.string,
  path: PropTypes.string
};

export default VenueLabel;
